import type GroupMessageModel from '@/models/GroupMessageModel';
import type MailMergeMessageModel from '@/models/MailMerge/MessageModel';
import type PreviewMessageModel from '@/models/MailMerge/PreviewMessageModel';
import type { Ref } from 'vue';

export default {
  build(
    message: MailMergeMessageModel,
    previousPreviewMessage: Ref<PreviewMessageModel | undefined>
  ) {
    const previewMessage = {
      ...previousPreviewMessage.value,
    } as PreviewMessageModel;

    previewMessage.id = message.id;
    previewMessage.recipientName = message.name;
    previewMessage.body = message.body;

    return previewMessage;
  },
  buildFirstPreviewMessage(groupMessage: GroupMessageModel) {
    return {
      ...groupMessage,
      ...groupMessage.messages[0],
      groupMessageId: groupMessage.id,
      body: '',
      ccEnabled: !!groupMessage.messages[0].cc.filter((e) => !!e).length,
      ccString: groupMessage.messages[0].cc.join(', '),
      bccEnabled: !!groupMessage.messages[0].bcc.filter((e) => !!e).length,
      bccString: groupMessage.messages[0].bcc.join(', '),
      workflow: groupMessage.workflow,
    } as unknown as PreviewMessageModel;
  },
  sync(
    previewMessage: PreviewMessageModel,
    groupMessage: Ref<GroupMessageModel | undefined>
  ) {
    if (!groupMessage.value) {
      return;
    }

    groupMessage.value!.messages.forEach((message) => {
      if (message.id === previewMessage.id) {
        message.body = previewMessage.body as string;
      }
    });
  },
};
