import { querySafeCompactField } from '@/mappers/Bryntum/FilterSortQueryMapper';
import type AnalyticsDashboardFE from '@/models/Dashboard/Canvas/AnalyticsDashboard';
import {
  PeriodSignals,
  type OverviewDashboardFE,
} from '@/models/Dashboard/Canvas/OverviewDashboard';
import { LearnerStatus, type LearnerFE } from '@/models/Dashboard/Learner';
import BryntumDashboardHelpers from '../Dashboard';
import type BryntumColumnHeader from '@/plugins/Bryntum/ColumnHeader';
import { LearnerStatusFilter } from '@/models/Dashboard/Learner';
import { useDashboardStore } from '@/stores/dashboard';
import type { LearnerActivityFE } from '@/models/Dashboard/Canvas/LearnerCanvasFE';
import { DashboardStatusHelper } from '@/plugins/DashboardStatusHelper';
import { useCurrentCanvasDataStore } from '@/stores/canvas/currentCanvasDataStore';
import { BRYNTUM_CANVAS_COLUMNS } from '@/mappers/Bryntum/Canvas/LearnerSnapshotMapper';

export const CANVAS_COURSE_COLUMN_ID = 'courseId';
export const CANVAS_SECTION_COLUMN_ID = 'sectionId';

const BryntumCanvasDashboardHelpers = {
  dashboardStatusRenderer: ({
    value,
    isExport,
    record,
    column,
  }: {
    value: string;
    isExport: boolean;
    record: { learner: LearnerFE; archived: boolean };
    column: { data: { startDate: Date; endDate: Date } };
  }) => {
    let cssClass = 'dashboard-risk-status';
    let spanInnerText = '';

    let attributes = '';

    if (isExport) {
      if (
        PeriodSignals[value as keyof typeof PeriodSignals] ==
        PeriodSignals.not_available
      ) {
        return '--';
      }

      return PeriodSignals[value as keyof typeof PeriodSignals];
    }

    cssClass += DashboardStatusHelper.cssClassByStatus({ value: value });

    if (value == LearnerStatus.ATTENTION_NEEDED) {
      if (!record.archived) {
        cssClass += ' dashboard-attn-needed-risk-status-tooltip';

        attributes += ` attn-needed-tooltip="true"`;
        attributes += ` learner-id="${record.learner.id}"`;
        attributes += ` dashboard-canvas-course-uuid="${record.learner.canvas?.courseUuid}"`;
        attributes += ` start-date="${column.data.startDate?.getTime()}"`;
        attributes += ` end-date="${column.data.endDate?.getTime()}"`;
      }
    }

    if (value == LearnerStatus.NOT_AVAILABLE) {
      spanInnerText = '--';
    }

    return `<span ${attributes} class="${cssClass}">${spanInnerText}</span>`;
  },
  missingAssignmentsPercentageRenderer: ({
    value,
  }: {
    value: number | null;
  }) => {
    if (typeof value === 'number') {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        // @ts-ignore
        roundingMode: 'trunc',
      }).format(value);
    } else {
      return '--';
    }
  },
  gradeRenderer: ({ value }: { value: string }) => {
    if (typeof value === 'number') {
      return value;
    } else {
      return '--';
    }
  },
  lastActivityRenderer: ({
    value,
    record,
    isExport,
  }: {
    value: string | number;
    record: { learner: LearnerFE; id: string };
    isExport: boolean;
  }) => {
    let finalValue = value;
    if (!value && value != 0) {
      finalValue = '--';
    }

    if (isExport) {
      return finalValue;
    }

    let cssClass = '';
    let attributes = '';

    if (showLastActivityTooltip(record.learner.canvas!.allActivities)) {
      cssClass += ' bryntum-dotted-cell';
      attributes += ' canvas-multiple-last-activity-tooltip=true';
      attributes += ` bryntum-record-id=${record.id}`;
    }

    return `<span class="${cssClass}" ${attributes}>${finalValue}</span>`;
  },
  sectionCourseColumn: (
    dashboard: OverviewDashboardFE | AnalyticsDashboardFE,
    section: boolean
    // true: section column of course dashboard.
    // false: course column of a rollup dashboard
  ) => {
    const dashboardBuildStrategy = useDashboardStore().dashboardBuildStrategy;
    const name = section
      ? BRYNTUM_CANVAS_COLUMNS.section
      : BRYNTUM_CANVAS_COLUMNS.course;

    const column = {
      id: name,
      name: name,
      plainText: name,
      field: section ? CANVAS_SECTION_COLUMN_ID : CANVAS_COURSE_COLUMN_ID,
      align: 'left',
      cellCls: 'bryntum-grid-cell-overflow',
      renderer: section
        ? dashboardBuildStrategy &&
          dashboardBuildStrategy.canvasAnalyticsFlow &&
          !dashboardBuildStrategy.canvasSectionId &&
          dashboard.canvas.sections.length > 1
          ? BryntumCanvasDashboardHelpers.cellSectionRenderer
          : BryntumCanvasDashboardHelpers.cellSectionAsSpanRenderer
        : BryntumCanvasDashboardHelpers.cellCourseRenderer,
      filterable: {
        filterFn: BryntumDashboardHelpers.filterGroupedFn,
        filterField: {
          type: 'combo',
          multiSelect: true,
          minWidth: 340,
          valueField: 'id',
          displayField: 'name',
          store: section
            ? useCurrentCanvasDataStore().usedSections
            : useCurrentCanvasDataStore().usedCourses,
          listeners: {
            catchAll: (event: { eventName: string }) => {
              // The idea to catch the event when filter modal is opened only.
              // Once user clicks on filter to compute all possible
              // values from presenting records at the moment.
              if (event.eventName == 'focusin') {
                useCurrentCanvasDataStore().update();
              }
            },
          },
        },
      },
    } as BryntumColumnHeader;
    return column;
  },
  learnerNameColumn: (
    dashboard: OverviewDashboardFE | AnalyticsDashboardFE
  ) => {
    const allFirstAndLastNamesPresent = dashboard.learners.every((learner) => {
      return learner.canvas?.firstName && learner.canvas.lastName;
    });

    if (allFirstAndLastNamesPresent) {
      return [
        {
          id: BRYNTUM_CANVAS_COLUMNS.firstName,
          name: 'First Name',
          plainText: 'First Name',
          field: BRYNTUM_CANVAS_COLUMNS.firstName,
          renderer: BryntumDashboardHelpers.cellLearnerRenderer,
          cellCls: 'bryntum-grid-cell-overflow',
          width: 80,
          align: 'left',
          sortable: BryntumDashboardHelpers.localeSorterBy(
            BRYNTUM_CANVAS_COLUMNS.firstName
          ),
        },
        {
          id: BRYNTUM_CANVAS_COLUMNS.lastName,
          name: 'Last Name',
          plainText: 'Last Name',
          field: BRYNTUM_CANVAS_COLUMNS.lastName,
          renderer: BryntumDashboardHelpers.cellLearnerRenderer,
          cellCls: 'bryntum-grid-cell-overflow',
          width: 80,
          align: 'left',
          sortable: BryntumDashboardHelpers.localeSorterBy(
            BRYNTUM_CANVAS_COLUMNS.lastName
          ),
        },
      ] as Array<BryntumColumnHeader>;
    } else {
      return [
        {
          id: 'Name',
          name: 'Name',
          plainText: 'Name',
          field: 'name',
          renderer: BryntumDashboardHelpers.cellLearnerRenderer,
          cellCls: 'bryntum-grid-cell-overflow',
          width: 130,
          align: 'left',
          sortable: BryntumDashboardHelpers.localeSorterBy('name'),
        },
      ] as Array<BryntumColumnHeader>;
    }
  },
  cellSectionRenderer: ({
    record,
    isExport,
  }: {
    record: { learner: LearnerFE; archived: boolean };
    isExport: boolean;
  }) => {
    if (isExport) return record.learner.canvas!.sectionName;

    if (record.archived) {
      return record.learner.canvas!.sectionName;
    }

    return `
        <span>
          <a
            course-uuid="${record.learner.canvas!.courseUuid}"
            section-id="${record.learner.canvas!.enrollment.courseSectionId}"
            class="bryntum-dotted-cell bryntum-course-anchor"
            bryntum-access-anchor="true"
            section-tooltip="true"
          >
            ${record.learner.canvas!.sectionName}
          </a>
        </span>
      `;
  },
  cellSectionAsSpanRenderer: ({
    record,
    isExport,
  }: {
    record: { Section: string; learner: LearnerFE };
    isExport: boolean;
  }) => {
    if (isExport) {
      return record.learner!.canvas!.sectionName;
    }
    return `<span>${record.Section}</span>`;
  },
  cellCourseRenderer: ({
    record,
    isExport,
  }: {
    record: { [key: string]: string };
    isExport: boolean;
  }) => {
    if (isExport) return record.courseCode;

    if (record.archived) {
      return record.courseCode;
    }

    return `
      <span>
        <a
          course-uuid="${record.courseUuid}"
          course-name="${querySafeCompactField(record.courseName)}"
          class="bryntum-dotted-cell bryntum-course-anchor"
          bryntum-access-anchor="true"
          course-tooltip="true"
        >
          ${record.courseCode}
        </a>
      </span>
    `;
  },
  tooltipCurrentStatusRenderer: ({
    record,
  }: {
    record: { 'current-status': string; archived: boolean };
  }) => {
    // If status is ATTENTION_NEEDED, we have custom tooltip.
    // Let's skip
    if (
      record['current-status'] == LearnerStatus.ATTENTION_NEEDED &&
      !record.archived
    ) {
      return undefined;
    } else {
      return LearnerStatusFilter[record['current-status']];
    }
  },
  gradeSortFunction: (
    raw1: { learner: LearnerFE },
    raw2: { learner: LearnerFE }
  ) => {
    let grade1 = -1;
    let grade2 = -1;

    if (raw1.learner.canvas!.enrollment.currentScore) {
      grade1 = raw1.learner.canvas!.enrollment.currentScore;
    }
    if (raw2.learner.canvas!.enrollment.currentScore) {
      grade2 = raw2.learner.canvas!.enrollment.currentScore;
    }

    return grade1 > grade2 ? 1 : -1;
  },
};

export default BryntumCanvasDashboardHelpers;

export function showLastActivityTooltip(
  allActivities: Array<LearnerActivityFE>
) {
  if (allActivities.length < 2) {
    return false;
  }

  const uniqDates: Array<Date> = [];

  allActivities.forEach((activity) => {
    if (
      !uniqDates.filter(
        (date) =>
          (date || '').toString() === (activity.datetime || '').toString()
      ).length
    ) {
      uniqDates.push(activity.datetime);
    }
  });

  // No need to show tooltip
  if (uniqDates.length < 2) {
    return false;
  }

  return true;
}
