import { ref } from 'vue';
import { defineStore } from 'pinia';
import { RequestError, type FormattedErrorObject } from '@/api/clientV2';

// This Pinia store is meant to handle the state of whichever user-facing error is being shown right now.
// It doesn't care *how* will it be displayed, it simply stores the error data
// Any component that needs to show an error to the user can import the store and use its features
export const useUserErrorStore = defineStore('userError', () => {
  const errorMessage = ref<string | null>(null);

  function setErrorMessage(message: string | null) {
    errorMessage.value = message;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleError(error: any) {
    if (error instanceof RequestError) handleNetworkError(error);
    else throw error;
  }

  function handleNetworkError(e: RequestError) {
    let errorMessages: string[] = [];
    const { errors } = e.body;
    if ([400, 422].includes(e.status)) {
      // Old error messages style
      // It's an array of strings
      if (typeof errors[0] == 'string') {
        errorMessages = errors as string[];
      } else {
        // New format of messages as objects
        (errors as FormattedErrorObject[]).forEach((error) => {
          error.messages.forEach((message) => {
            if (['base', 'global'].includes(error.attribute)) {
              errorMessages.push(message);
            } else {
              errorMessages.push(`${error.attribute} ${message}`);
            }
          });
        });
      }
    } else if (e.status == 500) {
      // RibbonError.removeProps(options,'Authorization')
      // TODO: what did the above do?
      errorMessages.push(
        "Looks like we are having a problem with the server. We have been alerted of this problem.\n\nPlease try again and if that still doesn't work feel free to reach out to us at support@ribbonedu.com"
      );
    } else if (e.status == 401) {
      errorMessages.push('You need to log in to complete that action.');
    } else if (e.status == 403) {
      errorMessages.push(
        "You don't have the required permissions in CanvasLMS. Please talk to your Canvas administrator and ask for Grades access to this course"
      );
    }
    errorMessage.value = errorMessages.join(', ');
  }

  return {
    errorMessage,
    setErrorMessage,
    handleError,
  };
});
