import type GroupMessageBEModel from '@/models/GroupMessageBEModel';
import type GroupMessageModel from '@/models/GroupMessageModel';

import mailMergeMessageMapper from './MailMerge/MessageMapper';
import UserHelper from '@/services/UserHelper';
import type { ActiveUser } from '@/models/ActiveUser';

export default {
  map(groupMessageBE: GroupMessageBEModel, timeZone: string) {
    const groupMessage = groupMessageBE as GroupMessageModel;

    groupMessage.showFirstMessagesCount = 7;

    groupMessage.recipientsAmountLabel =
      this.recipientsAmountLabel(groupMessage);
    groupMessage.formattedCreatedAt = this.formattedCreatedAt(groupMessage);

    groupMessage.senderLabel = this.senderLabel(groupMessage);

    if (groupMessageBE.messages) {
      groupMessage.messages = groupMessageBE.messages.map((message) => {
        return mailMergeMessageMapper.map(message, timeZone);
      });
    }

    groupMessage.fromName = this.fromName(
      groupMessage.sender.name,
      groupMessage.sender.email
    );

    return groupMessage;
  },
  fromName(name: string, email: string) {
    let fromName = '';

    if (name) {
      fromName += name;

      if (email) {
        fromName += ' <' + email + '>';
      }
    } else {
      fromName = email;
    }

    return fromName;
  },
  recipientsAmountLabel(groupMessage: GroupMessageModel) {
    let recipientsAmountLabel = groupMessage.recipientsCount + ' Recipient';

    if (groupMessage.recipientsCount > 1 || groupMessage.recipientsCount == 0) {
      recipientsAmountLabel += 's';
    }

    return recipientsAmountLabel;
  },
  formattedCreatedAt(groupMessage: GroupMessageModel) {
    const enUSFormatter = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    return enUSFormatter.format(new Date(groupMessage.createdAt));
  },
  senderLabel(groupMessage: GroupMessageModel) {
    return UserHelper.shortenedName({
      email: groupMessage.sender.email,
      name: groupMessage.sender.name,
    } as ActiveUser);
  },
};
