import type { AssignmentFE } from './Canvas/Assignment';
import type { CourseSectionFE } from './Canvas/CourseSection';
import type { LearnerBE, LearnerFE } from './Learner';
import type {
  RiskConfigurationBE,
  RiskConfigurationFE,
} from './RiskConfiguration';
import type { CourseDashboardBE, CourseDashboardFE } from '@/models/Dashboard';

export const ROLLUP_DASHBOARD_TYPE = 'rollup_dashboard';
export const COURSE_DASHBOARD_TYPE = 'course_dashboard';

export const DASHBOARD_CANVAS_ANALYTICS_VIEW_TYPE = 'canvas_analytics';
export const DASHBOARD_OVERVIEW_VIEW_TYPE = 'overview';

export const DASHBOARD_VIEWS: { [key: string]: string } = {};
DASHBOARD_VIEWS[DASHBOARD_CANVAS_ANALYTICS_VIEW_TYPE] =
  'Dashboard Canvas Analytics';
DASHBOARD_VIEWS[DASHBOARD_OVERVIEW_VIEW_TYPE] = 'Dashboard Overview';

export interface BaseDashboardBE {
  name: string;
  uuid: string;
  lastRunAt: Date;

  dashboardType: string;
  isDemo: boolean;

  courseDashboards: Array<CourseDashboardBE>;

  riskConfiguration: RiskConfigurationBE;
  learners: Array<LearnerBE>;

  archivedAt: Date;
}

export interface BaseDashboardFE {
  name: string;
  uuid: string;
  lastRunAt: Date;

  dashboardType: string;
  dashboardViewType: string;

  courseCode?: string;

  courseDashboards: Array<CourseDashboardFE>;

  riskConfiguration: RiskConfigurationFE;
  learners: Array<LearnerFE>;

  canvas: {
    assignments: Array<AssignmentFE>;
    sections: Array<CourseSectionFE>;
  };

  archivedAt: Date;
}
