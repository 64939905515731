import { useDashboardStore } from '@/stores/dashboard';
import { updateLearnersHeader, type BryntumStore } from './Dashboard';

export default {
  observe: () => {
    const dashboardAppearedInteval = setInterval(() => {
      const subgrid = document.querySelector('.b-grid-subgrid-normal');

      if (!subgrid) {
        return;
      }

      clearInterval(dashboardAppearedInteval);

      setTimeout(() => {
        subgrid.scrollLeft = subgrid.scrollWidth;
      }, 100);
    }, 50);
  },
  observeResize: () => {
    const dashboardAppearedInteval = setInterval(() => {
      const scrollableHeader = document.querySelector(
        '.b-grid-header-scroller.b-grid-header-scroller-normal'
      );

      if (!scrollableHeader) {
        return;
      }

      clearInterval(dashboardAppearedInteval);

      new ResizeObserver(function () {
        const dashboardScrollable =
          scrollableHeader.querySelector('div')!.scrollWidth -
            scrollableHeader.querySelector('.last-empty-bryntum-column')!
              .scrollWidth >
          scrollableHeader.scrollWidth;

        const lastColumnCells = document.querySelectorAll(
          '.last-empty-bryntum-column'
        );

        const dashboardStore = useDashboardStore();

        updateLearnersHeader(dashboardStore.grid!.store as BryntumStore);

        lastColumnCells.forEach((cell) => {
          if (dashboardScrollable) {
            cell.classList.add('hidden-last-empty-bryntum-column');
          } else {
            cell.classList.remove('hidden-last-empty-bryntum-column');
          }
        });
      }).observe(scrollableHeader);
    }, 50);
  },
};
