import 'vuetify/styles';
import { createVuetify } from 'vuetify';

const ribbonLightTheme = {
  dark: false,
  colors: {
    primary: '#6558F5',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'ribbonLightTheme',
    themes: {
      ribbonLightTheme,
    },
  },
  defaults: {
    VBtn: {
      variant: 'flat',
      class: 'no-text-transformation',
      rounded: 'lg',
    },
    VList: {
      rounded: 'lg',
    },
    VMenu: {
      offset: '10',
    },
  },
});
