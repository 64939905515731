export default interface PrivacySettingModel {
  enabled: boolean;
  resourceType: string;
  sharerUserId: number;
  toggledAt: Date;
  userDomain: string;
  editingDisabled: boolean;
}

export function canvasMessageType() {
  return 'MailMerge::CanvasMessage';
}

export function mailMergeMessageType() {
  return 'MailMerge::Message';
}

export function NoteType() {
  return 'Note';
}
