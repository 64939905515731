import { isNumber } from 'lodash';
import type { LocationQuery } from 'vue-router';

export interface BryntumFilter {
  id?: string;
  operator: string;
  property: string;
  value: string | number | Array<string>;
  caseSensitive: boolean;
}

const CASE_SENSITIVE_PREFIX = 'cs_';

export interface BryntumSort {
  ascending: boolean;
  field: string;
}

export interface FilterSortQueryParams {
  order: string;
  filter: string;
}

export function filterValueIsPresent(value: string | number | Array<string>) {
  return (
    value !== '' &&
    value != null &&
    ((value as Array<string>).length > 0 || isNumber(value))
  );
}

export default {
  toQueryParams(
    sorters: Array<BryntumSort>,
    filters: Array<BryntumFilter>,
    allQuery: LocationQuery
  ) {
    const allQueryWithoutSortAndFilter = JSON.parse(JSON.stringify(allQuery));

    delete allQueryWithoutSortAndFilter.order;
    delete allQueryWithoutSortAndFilter.filter;

    let orderQuery = '';
    if (sorters.length > 0) {
      orderQuery =
        'order=' +
        sorters
          .filter((sorter) => sorter.field != 'selected')
          .map((sorter) => {
            return (
              (sorter.ascending ? '' : '-') +
              querySafeCompactField(sorter.field)
            );
          })
          .join(';');
    }

    let filterQuery = '';
    if (
      filters.filter((filter) => filterValueIsPresent(filter.value)).length > 0
    ) {
      filterQuery = 'filter=' + buildFiltersToQueryValue(filters);
    }

    let finalQuery = '';

    if (orderQuery.length > 0 || filterQuery.length > 0) {
      finalQuery = '?';

      if (orderQuery.length > 0) {
        finalQuery += orderQuery;

        if (filterQuery.length > 0) {
          finalQuery += '&';
        }
      }

      if (filterQuery.length > 0) {
        finalQuery += filterQuery;
      }
    }

    if (finalQuery.length > 0) {
      if (
        new URLSearchParams(allQueryWithoutSortAndFilter).toString().length > 0
      ) {
        finalQuery +=
          '&' + new URLSearchParams(allQueryWithoutSortAndFilter).toString();
      }
    } else {
      finalQuery =
        '?' + new URLSearchParams(allQueryWithoutSortAndFilter).toString();
    }

    return finalQuery;
  },
  fromQueryParams(queryParams: FilterSortQueryParams) {
    const sorters: Array<BryntumSort> = [];
    const filters: Array<BryntumFilter> = [];

    queryParams.order?.split(',')?.forEach((param) => {
      const paramStartIndex = param[0] === '-' ? 1 : 0;

      sorters.push({
        ascending: param[0] != '-',
        field: param.substring(paramStartIndex),
      } as BryntumSort);
    });

    queryParams.filter?.split(';')?.forEach((param) => {
      const filterParam = param.split(':');

      let value: string | Array<string>;

      let operator = filterParam[1];

      const caseSensitive = operator.search(CASE_SENSITIVE_PREFIX) > -1;
      operator = operator.replace(CASE_SENSITIVE_PREFIX, '');
      // it means grouped filter
      if ((filterParam[2] as string).includes(',')) {
        // filter removes empty items generated from trailing commas
        value = filterParam[2].split(',').filter((item) => item);
        operator = '';
      } else {
        value = filterParam[2];
      }

      filters.push({
        property: filterParam[0],
        operator: operator,
        value: value,
        caseSensitive: caseSensitive,
      } as BryntumFilter);
    });

    return {
      filters,
      sorters,
    };
  },
};

export function querySafeCompactField(field: string) {
  return field.replaceAll(' ', '').replaceAll(',', '');
}

export function buildFiltersToQueryValue(filters: Array<BryntumFilter>) {
  return filters
    .filter((filter) => filterValueIsPresent(filter.value))
    .map((filter) => {
      let value = filter.value?.toString();
      let operator = filter.operator;

      if (filter.caseSensitive) {
        operator = CASE_SENSITIVE_PREFIX + operator;
      }

      if (Array.isArray(filter.value)) {
        // ['a'].toString() == 'a'.toString(), so we need to distinguish them
        // by adding a trailing comma to it.
        value += ',';
      }
      return (
        querySafeCompactField(filter.property) + ':' + operator + ':' + value
      );
    })
    .join(';');
}
