import { ref, computed, type Ref } from 'vue';
import { defineStore } from 'pinia';
import ribbonRequest, { webAppRequest } from '@/api/clientV2';
import router from '@/router';
import type { ActiveUser } from '@/models/ActiveUser';
import mixpanel from 'mixpanel-browser';

export const useActiveUserStore = defineStore('activeUser', () => {
  const hasOauthIntegration: Ref<boolean> = computed(() => {
    return activeUser?.value?.canvas?.type === 'oauth';
  });

  const activeUser: Ref<ActiveUser | undefined> = ref(undefined);

  const initialized = computed(() => activeUser.value !== undefined);

  const webapp = ref(false);

  let initPromise: Promise<void>;

  async function init(webappFlow: boolean) {
    if (webappFlow) webapp.value = true;

    // This pattern ensures loadActiveUser is only called once, even if init is called multiple times
    if (!initialized.value && !initPromise) initPromise = loadActiveUser();
    // If init is called at component A, and then at component B before the first call has been resolved,
    // component B will get the same Promise instance that component A initiated
    // and once it resolves, it will resolve for everyone
    await initPromise;
  }
  async function loadActiveUser() {
    let response: ActiveUser;
    if (webapp.value) {
      response = await webAppRequest('GET', `/api/v2/users/me`);
    } else {
      response = await ribbonRequest('GET', `/api/v2/users/me`);
    }
    activeUser.value = response;

    const userId = activeUser.value?.id;

    if (userId) {
      mixpanel.identify(userId.toString());
    }
  }

  async function logout() {
    await webAppRequest('DELETE', `/logout`);
    router.push('/login');
  }

  function mailMergeIntegrationNeeded() {
    return !activeUser.value!.mailMerge.integrated;
  }

  function canvasMessagesAllowed() {
    return activeUser.value!.canvasMessages.allowed;
  }

  function multiOrgUser() {
    return activeUser.value && activeUser.value.organizations.length > 1;
  }

  function belongsToSomeOrgs(orgNames: string[]) {
    return activeUser.value!.organizations.some((org) =>
      orgNames.includes(org.name)
    );
  }

  return {
    activeUser,
    belongsToSomeOrgs,
    hasOauthIntegration,
    init,
    initialized,
    logout,
    mailMergeIntegrationNeeded,
    canvasMessagesAllowed,
    multiOrgUser,
  };
});
