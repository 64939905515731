import { type LearnerFE, LearnerStatus } from '@/models/Dashboard/Learner';
import Email from '@/models/Email';
import { useDashboardStore } from '@/stores/dashboard';
import type { Grid, Model } from '@bryntum/grid';

interface SelectedLearner {
  checked: boolean;
  learner: LearnerFE;
  cellElement: string;
  validEmail: boolean;
  active: boolean;
}

export function learnerIsConcludedOrInactive(learner: LearnerFE) {
  return (
    learner.currentRiskStatus === LearnerStatus.CONCLUDED ||
    learner.currentRiskStatus === LearnerStatus.INACTIVE
  );
}

export default {
  map(grid: Grid) {
    const dashboardStore = useDashboardStore();
    return grid.data.map((row) => {
      // @ts-ignore
      const rowLearner = row.learner as LearnerFE;
      return {
        checked: dashboardStore.grid?.isSelected(row as Model),
        learner: rowLearner,
        cellElement: rowLearner.id.toString(),
        active: !learnerIsConcludedOrInactive(rowLearner),
        validEmail: Email.valid(rowLearner.email),
      } as SelectedLearner;
    });
  },
};

export type { SelectedLearner };
