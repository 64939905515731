import { ref, computed, type Ref } from 'vue';
import { defineStore } from 'pinia';

import { useActiveUserStore } from '@/stores/activeUser';
import ribbonRequest, { RequestError } from '@/api/clientV2';
import googleSpreadsheetService from '@/GoogleSpreadsheetService';
import { useUserErrorStore } from './userError';
import { orderBy, partition } from 'lodash';
interface SheetConfigurationsResponse {
  sheetConfiguration?: CanvasSheetConfig;
  courses: Array<CanvasCourse>;
}

export interface CanvasSheetConfig {
  id?: number | null;
  courseId?: number;
  atRiskMissing?: number | null;
  atRiskGradePerc?: number | null;
  periodLength?: number;
  creatorId?: number;
  googleSheetId?: string;
}
interface CanvasCourse {
  id: number;
  name: string;
  courseCode: string;
  startAt: string;
}

export const useCanvasConfigStore = defineStore('canvasConfig', () => {
  // We will need the ActiveUser information; if canvas isn't integrated we shouldn't attempt any canvas config API calls at all
  const activeUserStore = useActiveUserStore();

  // For error handling
  const userErrorStore = useUserErrorStore();

  // Why use both undefined and null?
  // Undefined is the state right after initialization, before retrieving the config through an API call.
  // When something is undefined, we don't know it's actual value, we are waiting for the response
  // Null represents when we *know* something to be empty
  // a null canvasSheetConfigId means the current spreadsheet doesn't have Canvas configured
  // A null atRiskGradePerc means it's not being used for AtRisk calculations
  const canvasSheetConfig: Ref<CanvasSheetConfig | null | undefined> =
    ref(undefined);
  const initialized = computed(() => canvasSheetConfig.value !== undefined);

  const canvasCourseList: Ref<Array<CanvasCourse>> = ref([]);

  // Below value will be undefined if the value hasn't loaded yet, and false if there is no canvas configuration id
  const isCanvasConfigured = computed(
    () => initialized.value && canvasSheetConfig.value !== null
  );
  const isCanvasIntegrated = computed(
    () => initialized.value && activeUserStore.activeUser?.canvas.integrated
  );

  // Canvas configuration is editable if it doesn't exist yet, or if the current user is it's creator
  const isEditable = computed(
    () =>
      isCanvasIntegrated.value &&
      (!isCanvasConfigured.value ||
        canvasSheetConfig.value?.creatorId == activeUserStore.activeUser?.id)
  );

  let initPromise: Promise<void>;
  async function init() {
    if (!initialized.value && !initPromise) initPromise = loadConfig();
    await initPromise;
  }

  async function loadConfig() {
    function sortCourseList(courses: CanvasCourse[]) {
      const [enabled, disabled] = partition(courses, (c) => c.startAt != null);
      return [...orderBy(enabled, 'courseCode'), ...disabled];
    }
    await activeUserStore.init(false);
    if (!activeUserStore.activeUser?.canvas.integrated) {
      // If canvas is not integrated, the current user won't be able to interact with canvas configurations at all
      canvasSheetConfig.value = null;
      return;
    }

    const canvasConfigId =
      await googleSpreadsheetService.getCanvasConfigurationId();
    try {
      if (canvasConfigId) {
        const response: SheetConfigurationsResponse = await ribbonRequest(
          'GET',
          `/api/v2/sheet_configurations/${canvasConfigId}`
        );
        canvasSheetConfig.value = response.sheetConfiguration;
        canvasCourseList.value = sortCourseList(response.courses);
      } else {
        // This means Canvas is not configured
        const response: SheetConfigurationsResponse = await ribbonRequest(
          'GET',
          `/api/v2/sheet_configurations/new`
        );
        canvasCourseList.value = sortCourseList(response.courses);
        canvasSheetConfig.value = null;
      }
    } catch (e) {
      handleError(e as Error);
    }
  }

  async function saveConfig(enabled: boolean, params: CanvasSheetConfig) {
    if (!isEditable.value) return; // Doesn't do anything if canvas config isn't editable

    const configured = isCanvasConfigured.value;
    try {
      if (!configured && !enabled) return;
      else if (!configured && enabled) await createCanvasConfig(params);
      else if (configured && enabled) await updateCanvasConfig(params);
      else if (configured && !enabled) await deleteCanvasConfig();
    } catch (e) {
      handleError(e as Error);
    }
  }

  function handleError(e: Error) {
    if (
      e instanceof RequestError &&
      e.status == 422 &&
      (e.body.errors as string[]).includes('Unable to refresh token')
    ) {
      // In the future we'll want to automatically deintegrate zoom and move them to the screen
      // This is the best suited place in the code to do that
      userErrorStore.setErrorMessage(
        'The Canvas integration has expired. Please go to the Integrations screen and re-integrate it'
      );
    } else {
      throw e;
    }
  }

  async function createCanvasConfig(params: CanvasSheetConfig) {
    const googleToken = await googleSpreadsheetService.getGoogleAccessToken();
    const googleSheetId = await googleSpreadsheetService.getSpreadsheetId();
    params = {
      ...params,
      googleSheetId,
    };
    const response: CanvasSheetConfig = await ribbonRequest(
      'POST',
      `/api/v2/sheet_configurations`,
      { ...params, googleToken }
    );
    canvasSheetConfig.value = response;
    googleSpreadsheetService.setSpreadsheetCanvasConfigurationId(response.id); // This is not awaited, we don't need this to block UI
  }

  async function updateCanvasConfig(params: CanvasSheetConfig) {
    const googleToken = await googleSpreadsheetService.getGoogleAccessToken();
    const response: CanvasSheetConfig = await ribbonRequest(
      'PUT',
      `/api/v2/sheet_configurations/${canvasSheetConfig.value!.id}`,
      { ...params, googleToken }
    );
    canvasSheetConfig.value = response;
  }

  async function deleteCanvasConfig() {
    await ribbonRequest(
      'DELETE',
      `/api/v2/sheet_configurations/${canvasSheetConfig.value!.id}`
    );
    canvasSheetConfig.value = null;
    googleSpreadsheetService.setSpreadsheetCanvasConfigurationId(null); // This is not awaited, we don't need this to block UI
  }
  return {
    init,
    initialized,
    isCanvasConfigured,
    isCanvasIntegrated,
    isEditable,
    canvasSheetConfig,
    canvasCourseList,
    saveConfig,
  };
});
