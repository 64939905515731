import type MailMessage from '@/models/MailMessage';

export function ccBccHandler(mailMessage: MailMessage) {
  if (mailMessage.ccEnabled) {
    mailMessage.cc = (mailMessage.ccString || '').split(',');
  } else {
    mailMessage.cc = [];
  }

  if (mailMessage.bccEnabled) {
    mailMessage.bcc = (mailMessage.bccString || '').split(',');
  } else {
    mailMessage.bcc = [];
  }

  return mailMessage;
}
