// It's a wrapper for pinia store
// It's implemented to have one method
// to fetch(in case it's not present or expired)
// and return needed value.
export default {
  initialized: false,
  data: {
    ribbonJWT: '',
    ribbonBackendUrl: '',
    googleAccessToken: '',
    dashboardTimeZone: '',
    canvasConfigurationId: '',
    zoomConfigurationId: '',
    spreadsheetId: '',
    featureFlags: {},
    learnersToComposeEmail: [],
  },

  async getFeatureFlags(flag: string) {
    if (!this.initialized) {
      await this.fetchGASdata();
    }
    return !!this.data.featureFlags[
      flag as keyof typeof this.data.featureFlags
    ];
  },

  async getRibbonJWT() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }
    return this.data.ribbonJWT;
  },

  async getRibbonBackendUrl() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.ribbonBackendUrl;
  },

  async getGoogleAccessToken() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.googleAccessToken;
  },

  async getDashboardTimeZone() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.dashboardTimeZone;
  },

  async getCanvasConfigurationId() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.canvasConfigurationId;
  },

  async getZoomConfigurationId() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.zoomConfigurationId;
  },

  async getSpreadsheetId() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.spreadsheetId;
  },

  async fetchGASdata() {
    if (sessionStorage.getItem('gasParams')) {
      this.data = JSON.parse(sessionStorage.getItem('gasParams') || '');
    } else {
      const gasData = await new Promise((r) => {
        // @ts-ignore
        google.script.run.withSuccessHandler(r).getVueData();
      });

      // @ts-ignore
      this.data = gasData;
    }
    this.initialized = true;
  },

  async setSpreadsheetCanvasConfigurationId(val: number | null | undefined) {
    // If val is null, it deletes it
    await new Promise((r) => {
      // @ts-ignore
      google.script.run.withSuccessHandler(r).setCanvasConfiguration(val);
    });
  },

  async setSpreadsheetZoomConfigurationId(val: number | null | undefined) {
    // If val is null, it deletes it
    await new Promise((r) => {
      // @ts-ignore
      google.script.run.withSuccessHandler(r).setZoomConfiguration(val);
    });
  },

  closeSpreadsheetDialog() {
    // @ts-ignore
    google.script.host.close();
  },

  openPrivacySettings() {
    // @ts-ignore
    google.script.run.openPrivacySettings();
    this.closeSpreadsheetDialog();
  },

  createGroupMessageSuccessCallback(scheduledAt: string | null) {
    // @ts-ignore
    google.script.run.createGroupMessageSuccessCallback(scheduledAt);
  },

  async getLearnersToComposeEmail() {
    if (!this.initialized) {
      await this.fetchGASdata();
    }

    return this.data.learnersToComposeEmail;
  },

  async runDashboard() {
    return await new Promise((r) => {
      // @ts-ignore
      google.script.run.withSuccessHandler(r).runDashboard();
    });
  },
};
