import type MailMergeMessageBEModel from './MessageBEModel';

export const MailMergeMessageContentStatus = {
  DRAFT: 'draft',
  GENERATING: 'generating',
};

export default interface MailMergeMessageModel extends MailMergeMessageBEModel {
  formattedStatusUpdatedAt: string;
  contentStatus: string;
}
