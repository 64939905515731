import type { LearnerEvent } from '../LearnerProfile/Event/Event';
import type MailMergeMessageBEModel from '../MailMerge/MessageBEModel';
import type { NoteBE } from '../Note';
import type LearnerCanvasFE from './Canvas/LearnerCanvasFE';

export const LearnerStatus = {
  ON_TRACK_STATUS: 'on_track',
  ON_TRACK_WARN: 'on_track_warn',
  ATTENTION_NEEDED: 'attention_needed',
  CONCLUDED: 'concluded',
  INACTIVE: 'deactivated',
  REJECTED: 'rejected',
  NOT_AVAILABLE: 'not_available',
};

export const LearnerStatusFilter: { [key: string]: string } = {};
LearnerStatusFilter[LearnerStatus.ON_TRACK_STATUS] = 'On Track';
LearnerStatusFilter[LearnerStatus.ATTENTION_NEEDED] = 'Attention Needed';
LearnerStatusFilter[LearnerStatus.CONCLUDED] = 'Concluded';
LearnerStatusFilter[LearnerStatus.INACTIVE] = 'Inactive';
LearnerStatusFilter[LearnerStatus.NOT_AVAILABLE] = 'n/a';

interface LearnerBase {
  id: number;
  name: string;
  email: string;
  currentRiskStatus?: string;
}

export interface LearnerBE extends LearnerBase {
  canvas?: {
    id: number;
    enrollmentId: number;
    domain: string;
    firstName: string;
    lastName: string;
  };
  messages?: Array<MailMergeMessageBEModel>;
  notes?: Array<NoteBE>;
}

export interface LearnerFE extends LearnerBase {
  currentStatus: string;
  canvas?: LearnerCanvasFE;
  events: Array<LearnerEvent>;

  searchableString: string;

  dashboardUuid: string;

  archived: boolean;
}
