import googleSpreadsheetService from '@/GoogleSpreadsheetService';
import { useActiveUserStore } from '@/stores/activeUser';
import { transform, camelCase, snakeCase } from 'lodash';

interface ErrorBody {
  errors: string[] | FormattedErrorObject[];
}
export class RequestError extends Error {
  status: number;
  body: ErrorBody;
  constructor({ status, body }: { status: number; body: ErrorBody }) {
    super(`${status} error during the request`);
    this.status = status;
    this.body = body;
  }
}
export interface FormattedErrorObject {
  attribute: string;
  messages: string[];
}

export async function webAppRequest(method: string, path: string, body = {}) {
  const token = 'token';

  try {
    return await request(
      method,
      path,
      body,
      import.meta.env.VITE_BACKEND_URL as string,
      token
    );
  } catch (e) {
    if (e instanceof RequestError) {
      switch (e.status) {
        case 401:
          window.location.href = '/login';
          break;
        case 460:
          window.location.href = '/onboarding/canvas';
          break;
        case 404:
          // If user belongs to multiple orgs (at the moment only one user)
          // may happen that on different tabs he has different users logged in.
          // In this case on inactive tab he will receive every time 404 for any action.
          // Just reload will fix it.
          if (useActiveUserStore().multiOrgUser()) {
            window.location.reload();
          } else {
            throw e;
          }

          break;
        default:
          throw e;
      }
      // Firefox throws a TypeError when the user is not authorized and the request is blocked by the browser
      // Safary is the same but the message is different(Load failed)
    } else if (
      e instanceof TypeError &&
      (e.message == 'NetworkError when attempting to fetch resource.' ||
        e.message == 'Load failed')
    ) {
      return;
    } else {
      throw e;
    }
  }
}

export default async function gasRequest(
  method: string,
  path: string,
  body = {}
) {
  const backendUrl = await googleSpreadsheetService.getRibbonBackendUrl();
  const token = await googleSpreadsheetService.getRibbonJWT();

  return request(method, path, body, backendUrl, token);
}

async function request(
  method: string,
  path: string,
  body: Object | string | Array<Object>,
  backendUrl: string,
  token: string
) {
  // Method: HTTP method
  const requestOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: method,
    credentials: 'include',
  };

  const requestBody = deepTransformValue(body, snakeCase);
  if (method != 'GET') {
    requestOptions.body = JSON.stringify(requestBody);
  }

  const route = `${backendUrl}${path}`;

  // In case you can't catch the exception, it means you are not awaiting it.
  const response = await fetch(route, requestOptions);

  if (response.status == 204) return null;

  let responseBody;
  try {
    responseBody = await response.json();
  } catch (e) {
    if (e instanceof SyntaxError) {
      // It means the body is not json. It could be empty
      if (response.ok) return null;
      else
        throw new RequestError({
          status: response.status,
          body: responseBody,
        });
    }
  }

  if (!response.ok) {
    throw new RequestError({
      status: response.status,
      body: responseBody,
    });
  }

  return deepTransformValue(responseBody, camelCase);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepTransformValue(item: any, transformer: Function): any {
  if (Array.isArray(item)) {
    // recursive case: it's an array
    return item.map((i) => deepTransformValue(i, transformer));
  } else if (item instanceof Object) {
    // recursive case: it's a hash
    return transform(item, (acc, v, k) => {
      // Transforms both the key and the value
      const newKey = typeof k === 'string' ? transformer(k) : k;
      acc[newKey] = deepTransformValue(v, transformer);
    });
  } else {
    // base case
    return item;
  }
}
