import {
  filterValueIsPresent,
  type BryntumFilter,
} from '@/mappers/Bryntum/FilterSortQueryMapper';

import { useDashboardStore } from '@/stores/dashboard';
import { learnersCountForDashboard } from '@/services/LearnersCount';
import { learnerIsConcludedOrInactive } from '@/mappers/Bryntum/LearnersMapper';
import type { LearnerFE } from '@/models/Dashboard/Learner';
import { BRYNTUM_CANVAS_COLUMNS } from '@/mappers/Bryntum/Canvas/LearnerSnapshotMapper';

export interface BryntumStore {
  filters: {
    values: Array<BryntumFilter>;
  };
}

export function toggleCollapseListener() {
  const dashboardStore = useDashboardStore();
  const gridColumns = dashboardStore.grid!.columns as { find: Function };

  const firstAndLastNameFlow = !gridColumns.find(
    (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.fullName
  );

  // If first and last name present let's show both on collapsed dashboard
  if (firstAndLastNameFlow) {
    gridColumns.find(
      (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.firstName
    ).hidden = false;
    gridColumns.find(
      (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.lastName
    ).hidden = false;
  } else {
    gridColumns.find(
      (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.fullName
    ).hidden = false;
  }

  const courseColumnPresent = !!gridColumns.find(
    (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.course
  );

  if (courseColumnPresent) {
    gridColumns.find(
      (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.course
    ).hidden = false;
  } else {
    gridColumns.find(
      (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.section
    ).hidden = false;
  }

  gridColumns.find(
    (c: { id: string }) => c.id == BRYNTUM_CANVAS_COLUMNS.currentStatus
  ).hidden = false;

  recalculateLockedPartWidth();

  if (dashboardStore.loaded) {
    updateLearnersHeader(dashboardStore.grid?.store as BryntumStore);
  }
}

function recalculateLockedPartWidth() {
  let newWidth = 0;
  document
    .querySelectorAll(
      '.b-grid-subgrid-locked .b-grid-row:last-child .b-grid-cell'
    )
    .forEach((e) => (newWidth += e.clientWidth));

  useDashboardStore().grid!.subGrids.locked.width = newWidth;
}

// It's used to render the header of the grid as html
export function headerRenderer({
  column,
  isExport,
}: {
  column: { data: { text: string; plainText: boolean } };
  isExport: boolean;
}) {
  if (isExport) return column.data.plainText;
  return column.data.text;
}

export function updateLearnersHeader(event: BryntumStore) {
  const headerContainer = document.querySelectorAll(
    '.learners-column-header-container .b-grid-header-text-content'
  )[0];

  if (!headerContainer) {
    return;
  }

  const filters = event.filters.values.filter((filter) =>
    filterValueIsPresent(filter.value)
  );

  const dashboardStore = useDashboardStore();
  const dataLength = learnersCountForDashboard(
    // @ts-ignore
    dashboardStore.grid?.initialConfig.data
  );
  if (filters.length == 0) {
    headerContainer.innerHTML = defaultLearnersHeaderText(dataLength);
  } else {
    // @ts-ignore
    const filteredCount = learnersCountForDashboard(dashboardStore.grid?.store);
    headerContainer.innerHTML = `
      <div class='learners-list-filter-applied'>
        <span class='amount-of-filter'>${filters.length} filter(s)</span>
        <span class='learners-list-filter-count'>${filteredLearnersHeaderText(
          dataLength,
          filteredCount
        )}
      </div>`;

    addClearButton();
  }
}

function addClearButton() {
  const clearFilterHref = document.createElement('a');
  clearFilterHref.classList.add('clear-filters');
  clearFilterHref.innerText = 'Clear';
  clearFilterHref.addEventListener('click', () => {
    const dashboardStore = useDashboardStore();
    // @ts-ignore
    dashboardStore.grid?.store.clearFilters();
  });

  document
    .querySelectorAll('.learners-list-filter-applied')[0]
    .appendChild(clearFilterHref);
}

export function defaultLearnersHeaderText(studentsCount: number) {
  return `Viewing all ${studentsCount} unique Learners`;
}
export function filteredLearnersHeaderText(
  totalCount: number,
  filteredCount: number
) {
  return `Viewing ${filteredCount} out of ${totalCount} unique Learners`;
}

const BryntumDashboardHelpers = {
  htmlRenderer: ({ value }: { value: string }) => {
    return value;
  },
  cellLearnerRenderer: ({
    value,
    record,
    isExport,
    column,
  }: {
    value: string;
    record: { name: string; courseUuid: string; learner: LearnerFE };
    isExport: boolean;
    column: { field: string };
  }) => {
    if (isExport) {
      return value;
    }

    const extraClass = learnerIsConcludedOrInactive(record.learner)
      ? 'inactive'
      : '';

    if (column.field == BRYNTUM_CANVAS_COLUMNS.lastName) {
      return `
        <span class='black-color'>${
          record[column.field as keyof typeof record]
        }</span>
      `;
    }

    return `
    <span>
      <a
        user-id="${record.learner.id}"
        class="bryntum-dotted-cell bryntum-user-anchor ${extraClass}"
        bryntum-access-anchor="true"
        course-uuid="${record.courseUuid}"
      >
        ${record[column.field as keyof typeof record]}
      </a>
    </span>
  `;
  },
  filterGroupedFn: ({
    record,
    value,
    property,
  }: {
    record: { [key: string]: string };
    value: Array<string>;
    property: string;
  }) => {
    return !value.length || value.includes(record[property]);
  },
  // A function that takes as a param the name of a field inside a Bryntum row
  // And returns a lowercase-ignoring, locale specific sorter function
  localeSorterBy:
    (field: string) =>
    (row1: { [key: string]: string }, row2: { [key: string]: string }) =>
      // Bryntum recommends against using LocaleCompare directly, so we use Collator instead
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
      new Intl.Collator('en').compare(row1[field], row2[field]),
};
export default BryntumDashboardHelpers;

export function emptyRenderer() {
  return '';
}
