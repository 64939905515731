import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import type PrivacySettingModel from '@/models/PrivacySetting';
import {
  canvasMessageType,
  mailMergeMessageType,
  NoteType,
} from '@/models/PrivacySetting';

import ribbonRequest, { webAppRequest } from '@/api/clientV2';

export const usePrivacySettingsStore = defineStore('privacySettings', () => {
  const privacySettings: Ref<Array<PrivacySettingModel> | undefined> =
    ref(undefined);

  const initialized: Ref<Boolean> = ref(false);

  async function init(webapp: boolean) {
    if (initialized.value) return;
    initialized.value = true;

    let request;

    if (webapp) {
      request = webAppRequest('GET', '/api/v2/sharing_settings');
    } else {
      request = ribbonRequest('GET', '/api/v2/sharing_settings');
    }

    request.then((sharingSettings) => {
      privacySettings.value = sharingSettings.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (setting: any) => setting as PrivacySettingModel
      );
    });
  }

  function canvasMessageSetting() {
    return privacySettings.value?.filter((setting) => {
      return setting.resourceType == canvasMessageType();
    })[0];
  }

  function mailMergeSetting() {
    return privacySettings.value?.filter((setting) => {
      return setting.resourceType == mailMergeMessageType();
    })[0];
  }

  function noteSetting() {
    return privacySettings.value?.filter((setting) => {
      return setting.resourceType == NoteType();
    })[0];
  }

  function toggle(setting: PrivacySettingModel) {
    webAppRequest('POST', '/api/v2/sharing_settings', setting);
  }

  return {
    init,
    canvasMessageSetting,
    mailMergeSetting,
    noteSetting,
    toggle,
  };
});
