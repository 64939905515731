import { webAppRequest } from '@/api/clientV2';
import PreviewMessageMapper from '@/mappers/MailMerge/PreviewMessageMapper';
import type { ActiveUser } from '@/models/ActiveUser';
import type ComposeEmailRecipient from '@/models/ComposeEmail/Recipient';
import type AnalyticsDashboardFE from '@/models/Dashboard/Canvas/AnalyticsDashboard';
import type { OverviewDashboardFE } from '@/models/Dashboard/Canvas/OverviewDashboard';
import type GroupMessageModel from '@/models/GroupMessageModel';
import type PreviewMessageModel from '@/models/MailMerge/PreviewMessageModel';
import type MailMessage from '@/models/MailMessage';
import type { Grid } from '@bryntum/grid';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import groupMessageMapper from '@/mappers/GroupMessageMapper';
import { useTimeZoneStore } from '@/stores/timeZone';
import { MailMergeMessageContentStatus } from '@/models/MailMerge/MessageModel';
import { useGroupMessagesStore } from './groupMessages';
import { useDashboardStore } from './dashboard';
import { ccBccHandler } from '@/services/MailMessage/CcBccHandler';
import { MailMergeMessageBuilder } from '@/builders/MailMerge/MessageBuilder';

export const useComposeEmailStore = defineStore('composeEmail', () => {
  const dialogOpened = ref(false);
  const emailsSentDialogOpened = ref(false);
  const previewDialogOpened = ref(false);
  const webApp = ref(false);

  const mailMessage = ref<MailMessage>();
  const recipients = ref<Array<ComposeEmailRecipient>>();

  const draftGroupMessage = ref<GroupMessageModel>();

  const selfComposeFlow = 'self-compose';
  const writeForMeFlow = 'write-for-me';

  const currentFlow = ref(selfComposeFlow);

  async function init(
    activeUser: ActiveUser,
    dashboard: AnalyticsDashboardFE | OverviewDashboardFE,
    grid: Grid,
    force: boolean,
    isCanvasConversation: boolean
  ) {
    const messageBuilder = new MailMergeMessageBuilder(
      activeUser,
      isCanvasConversation
    );

    // GAS does not run this method.
    webApp.value = true;

    // It means user clicked on "Go back" and we don't need to recreate mailMessage.
    if (!force && mailMessage.value) {
      dialogOpened.value = true;
      return;
    }

    if (force) {
      await messageBuilder.authorize(activeUser);

      currentFlow.value = selfComposeFlow;

      messageBuilder.buildMessage(dashboard);
      messageBuilder.withFromEmail();
      messageBuilder.withAutoBcc();

      mailMessage.value = messageBuilder.mailMessage;
    }

    // It should be a getter for message but at the moment we should
    // support GAS users
    recipients.value = messageBuilder.buildRecipients(grid);

    dialogOpened.value = true;
  }

  function closeDialog(markMessageCancelled = false) {
    dialogOpened.value = false;
    emailsSentDialogOpened.value = false;
    previewDialogOpened.value = false;

    if (markMessageCancelled) {
      cancelDraft();
    }
  }

  function openPreviewDialog() {
    closeDialog();
    previewDialogOpened.value = true;
  }

  function openComposeEmailDialog(markMessageCancelled: boolean) {
    if (markMessageCancelled) {
      cancelDraft();
    }

    closeDialog();
    dialogOpened.value = true;
  }

  function cancelDraft() {
    if (draftGroupMessage.value) {
      webAppRequest(
        'PUT',
        `/api/v2/mail_merge/group_messages/${draftGroupMessage.value!.id}/`,
        {
          mail_merge_group_message: {
            action: 'cancel',
          },
        }
      );
    }

    draftGroupMessage.value = undefined;
  }

  function saveDraftMessage(mailMessage: PreviewMessageModel) {
    webAppRequest(
      'PUT',
      `/api/v2/mail_merge/group_messages/${mailMessage.groupMessageId}/messages/${mailMessage.id}`,
      {
        mail_merge_message: mailMessage,
      }
    );

    PreviewMessageMapper.sync(mailMessage, draftGroupMessage!);
  }

  async function confirmDraft(latestPreviewMessage: PreviewMessageModel) {
    ccBccHandler(latestPreviewMessage);

    const response = await webAppRequest(
      'PUT',
      `/api/v2/mail_merge/group_messages/${draftGroupMessage.value!.id}`,
      {
        mail_merge_group_message: {
          ...latestPreviewMessage,
          cc: latestPreviewMessage.cc,
          bcc: latestPreviewMessage.bcc,
          action: 'send',
          timezone: await useTimeZoneStore().userTimeZone(),
        },
      }
    );
    const groupMessagesStore = useGroupMessagesStore();
    const dashboardStore = useDashboardStore();
    const timeZone = await useTimeZoneStore().userTimeZone();
    const mappedMessage = groupMessageMapper.map(response, timeZone);
    groupMessagesStore.addGroupMessage(mappedMessage);
    dashboardStore.uncheckAllLearners();
    closeDialog();
    emailsSentDialogOpened.value = true;

    draftGroupMessage.value = undefined;
  }

  function waitingForGeneratedEmails() {
    if (!draftGroupMessage.value) {
      return true;
    }

    return (
      draftGroupMessage.value!.messages.filter(
        (m) => m.contentStatus == MailMergeMessageContentStatus.GENERATING
      ).length > 0
    );
  }

  async function refreshDraftGroupEmail(id: number, force: boolean) {
    await webAppRequest('GET', `/api/v2/mail_merge/group_messages/${id}`).then(
      async (response) => {
        const draftMessage = groupMessageMapper.map(
          response,
          await useTimeZoneStore().userTimeZone()
        );

        if (force) {
          if (
            draftMessage.messages.length ==
            mailMessage.value!.recipients.filter((r) => r.checked)!.length
          ) {
            draftGroupMessage.value = draftMessage;
          } else {
            setTimeout(() => {
              refreshDraftGroupEmail(id, true);
            }, 2000);

            return;
          }
        }

        draftMessage.messages.forEach((message) => {
          if (message.contentStatus == MailMergeMessageContentStatus.DRAFT) {
            const draftMessage = draftGroupMessage.value!.messages.find(
              (m) => m.id == message.id
            )!;

            draftMessage.body = message.body;
            draftMessage.contentStatus = message.contentStatus;
          }
        });

        setTimeout(() => {
          if (waitingForGeneratedEmails() && draftGroupMessage.value) {
            refreshDraftGroupEmail(draftGroupMessage.value!.id, false);
          }
        }, 5000);
      }
    );
  }

  function changeFlow(flow: string) {
    currentFlow.value = flow;

    if (currentFlow.value == writeForMeFlow) {
      mailMessage.value!.action = 'generate';
    } else {
      mailMessage.value!.action = undefined;
    }
  }

  return {
    dialogOpened,
    emailsSentDialogOpened,
    previewDialogOpened,
    mailMessage,
    webApp,
    init,
    recipients,
    closeDialog,
    openPreviewDialog,
    draftGroupMessage,
    saveDraftMessage,
    confirmDraft,
    openComposeEmailDialog,
    refreshDraftGroupEmail,
    waitingForGeneratedEmails,
    currentFlow,
    selfComposeFlow,
    writeForMeFlow,
    changeFlow,
  };
});
