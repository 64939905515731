import type BryntumColumnHeader from '@/plugins/Bryntum/ColumnHeader';
import type BryntumDashboard from '@/plugins/Bryntum/BryntumDashboard';
import FilterSortQueryMapper, {
  type FilterSortQueryParams,
} from '@/mappers/Bryntum/FilterSortQueryMapper';
import type {
  BryntumFilter,
  BryntumSort,
} from '@/mappers/Bryntum/FilterSortQueryMapper';

import {
  toggleCollapseListener,
  headerRenderer,
  updateLearnersHeader,
  emptyRenderer,
  type BryntumStore,
} from '@/plugins/Bryntum/Dashboard';
import router from '@/router';

import { useDashboardStore } from '@/stores/dashboard';
import type { Model } from '@bryntum/grid';
import { LEARNER_STATUS_KEY } from './Canvas/LearnerSnapshotMapper';
import { LearnerStatus } from '@/models/Dashboard/Learner';
import { v4 } from 'uuid';

const DashboardMapper = {
  map(
    dashboard: BryntumDashboard,
    queryFilterSortParams: { filters: BryntumFilter[]; sorters: BryntumSort[] }
  ) {
    this.applyDefaultFilter(queryFilterSortParams.filters);
    // Workaround from
    // https://forum.bryntum.com/viewtopic.php?p=139916&sid=939ed71a23474e6af25654ac28b318d8#p139916
    this.fixMultipleFilters(queryFilterSortParams.filters);

    return {
      features: {
        search: true,
        filter: true,
        sort: {
          prioritizeColumns: true,
          field: queryFilterSortParams.sorters[0]?.field,
          ascending: queryFilterSortParams.sorters[0]?.ascending,
        },
        cellTooltip: {
          hoverDelay: 100,
          textContent: true,
        },
        // All possible configs
        // https://www.bryntum.com/products/scheduler/docs/#Scheduler/view/Scheduler#config-showRemoveEventInContextMenu
        rowCopyPaste: false,
        cellMenu: false,
        headerMenu: false,
        // Disable group as it fails in some columns but in general interesting feature.
        group: false,
        columnPicker: false,
      },
      selectionMode: {
        cell: false,
        column: false,
        rowNumber: false,
        multiSelect: true,
        dragSelect: false,
        selectOnKeyboardNavigation: false,
        checkbox: true,
        checkboxOnly: true,
        showCheckAll: true,
        deselectOnClick: true,
        deselectFilteredOutRecords: false,
      },
      columns: this.mapColumns(dashboard),
      data: this.mapValues(dashboard),
      store: {
        listeners: {
          sort: this.sortListener,
          filter: this.filterListener,
        },
        filters: queryFilterSortParams.filters,
      },
      listeners: {
        cellClick: this.selectRecordListener,
      },
      rowHeight: 40,
    };
  },
  mapColumns(dashboard: BryntumDashboard) {
    return [
      {
        locked: true,
        collapsible: true,
        collapsed: false,
        collapseMode: 'toggleAll',
        cls: 'locked-columns learners-column-header-container',
        text: '',
        align: 'left',
        listeners: {
          toggleCollapse: toggleCollapseListener,
        },
        children: [...this.mapChildColumn(dashboard.userColumns, false, false)],
      },
      ...this.mapColumn(
        // It's used wo scretch the last column in case dashboard is small.
        // https://linear.app/ribbon-education/issue/RIB-1028
        dashboard.columns.concat([
          {
            id: 'empty-column',
            name: '',
            cls: 'last-empty-bryntum-column',
            cellCls: 'last-empty-bryntum-column',
            collapsed: false,
            collapsible: false,
            children: [
              {
                id: 'empty-column-nested',
                name: '',
                plainText: '',
                cls: 'last-empty-bryntum-column',
                cellCls: 'last-empty-bryntum-column',
                minWidth: 1,
                width: 1,
                renderer: emptyRenderer,
                searchable: false,
              } as BryntumColumnHeader,
            ],
          } as BryntumColumnHeader,
        ] as Array<BryntumColumnHeader>)
      ),
    ];
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapColumn(columns: Array<BryntumColumnHeader>): Array<any> {
    return columns.map((column, index) => {
      const isColorized = index % 2 == 0;
      let cls = '';
      let cellCls = '';

      if (isColorized) {
        cls = 'colorized-row';
        cellCls = 'colorized-row';
      }

      if (column.cls) {
        cls += ' ' + column.cls;
      }

      if (column.cellCls) {
        cellCls += ' ' + column.cellCls;
      }

      if (column.collapsed) {
        column.children![0].width = 140;
      }

      return {
        text: column.name,
        plainText: column.plainText,
        headerRenderer: headerRenderer,
        cls: cls,
        cellCls: cellCls,
        align: column.collapsed ? 'left' : 'center',
        collapsible: column.collapsible,
        collapsed: column.collapsed,
        children: this.mapChildColumn(
          column.children!,
          isColorized,
          index >= columns.length - 2
        ),
      };
    });
  },
  mapChildColumn(
    columns: Array<BryntumColumnHeader>,
    isColorized: boolean,
    oneOfLastColumn: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Array<any> {
    return columns.map((column) => {
      const {
        id,
        name: text,
        cls: columnCls,
        cellCls: columnCellCls,
        ...extraParams
      } = column;

      let cls = '';
      let cellCls = '';

      if (isColorized) {
        cls = 'colorized-row';
        cellCls = 'colorized-row';
      }

      if (columnCls) {
        cls += ' ' + columnCls;
      }

      if (columnCellCls) {
        cellCls += ' ' + columnCellCls;
      }

      if (oneOfLastColumn) {
        extraParams.resizable = false;
      }

      return {
        id,
        field: id,
        text,
        headerRenderer: headerRenderer,
        width: 90,
        cls,
        cellCls,
        htmlEncode: false,
        editor: false,
        draggable: false,
        align: 'center', // Overriden by align inside extraParams, if it exists
        ...extraParams,
      };
    });
  },
  mapValues(dashboard: BryntumDashboard) {
    return dashboard.users.map((user) => {
      const userObject: Record<
        string | number,
        string | number | boolean | null | undefined
      > = {};

      user.userInfo.forEach((attribute) => {
        userObject[attribute.key] = attribute.value;
      });
      user.cells.forEach((attribute) => {
        userObject[attribute.key] = attribute.value;
      });

      userObject.selected = false;

      return userObject;
    });
  },
  filterListener(event: BryntumStore) {
    router.push(
      `${FilterSortQueryMapper.toQueryParams(
        FilterSortQueryMapper.fromQueryParams(
          router.currentRoute.value.query as unknown as FilterSortQueryParams
        ).sorters,
        event.filters.values.filter((filter) => filter.operator.length),
        router.currentRoute.value.query
      )}`
    );

    updateLearnersHeader(event);
  },
  sortListener(event: {
    source: {
      sorters: Array<BryntumSort>;
    };
  }) {
    router.push(
      `${FilterSortQueryMapper.toQueryParams(
        event.source.sorters,
        FilterSortQueryMapper.fromQueryParams(
          router.currentRoute.value.query as unknown as FilterSortQueryParams
        ).filters,
        router.currentRoute.value.query
      )}`
    );
  },
  selectRecordListener(event: {
    record: Model;
    cellSelector: { id: string };
    target: HTMLElement;
  }) {
    const anchor = event.target.closest('a');
    // if action link clicked don't select record.
    if (anchor && anchor.getAttribute('bryntum-access-anchor')) {
      return;
    }

    if (event.target.closest('[attn-needed-tooltip="true"]')) {
      return;
    }

    const dashboardGrid = useDashboardStore().grid!;
    if (dashboardGrid.isSelected(event.record)) {
      dashboardGrid.deselectRow(event.record);
    } else {
      dashboardGrid.selectRow({
        record: event.record,
        addToSelection: true,
      });
    }
  },
  applyDefaultFilter(filters: Array<BryntumFilter>) {
    // If it's already applied let's not touch it.
    if (filters.find((f) => f.property == LEARNER_STATUS_KEY)) {
      return;
    }

    // It's search by learner id. It may happen that learner
    // is concluded. Let's ignore default status filter
    if (filters.find((f) => f.property == 'learner-id')) {
      return;
    }

    filters.push({
      operator: '',
      property: LEARNER_STATUS_KEY,
      value: [
        LearnerStatus.ON_TRACK_STATUS,
        LearnerStatus.ATTENTION_NEEDED,
        LearnerStatus.NOT_AVAILABLE,
      ],
    } as BryntumFilter);
  },
  fixMultipleFilters(filters: Array<BryntumFilter>) {
    filters.forEach((filter) => {
      if (filters.filter((f) => f.property == filter.property).length > 1) {
        filter.id = v4();
      }
    });
  },
};

export default DashboardMapper;
