import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import groupMessageMapper from '@/mappers/GroupMessageMapper';

import ribbonRequest, { webAppRequest } from '@/api/clientV2';

import type GroupMessageBEModel from '@/models/GroupMessageBEModel';
import type GroupMessageModel from '@/models/GroupMessageModel';
import googleSpreadsheetService from '@/GoogleSpreadsheetService';
import type MailMergeMessageModel from '@/models/MailMerge/MessageModel';

export const useGroupMessagesStore = defineStore('groupMessages', () => {
  const dialogOpened = ref(false);
  const webApp = ref(false);
  const request = computed(() =>
    webApp.value ? webAppRequest : ribbonRequest
  );

  const messages = ref<GroupMessageModel[] | undefined>(undefined);

  const selectedGroupMessage = ref<GroupMessageModel | undefined | null>(
    undefined
  );

  const currentPersonalMessage = ref<MailMergeMessageModel>();

  const initialized = ref<Boolean>(false);

  async function init() {
    if (initialized.value) return;
    initialized.value = true;

    let request: Function = ribbonRequest;

    if (webApp.value) {
      request = webAppRequest;
    }

    request('GET', '/api/v2/mail_merge/group_messages')
      .then(async (groupMessagesResponse: Array<GroupMessageBEModel>) => {
        const timeZone = await userTimeZone();
        messages.value = groupMessagesResponse.map(
          (groupMessage: GroupMessageBEModel) => {
            return groupMessageMapper.map(groupMessage, timeZone);
          }
        );
      })
      .then(() => {
        // If no message it will show "No messages found" label
        loadCurrentGroupMessage(
          // By default sent tab is opened.
          // We need to show latest sent emai once Campaigns is opened.
          messages.value!.filter((m) => !m.scheduledAt)[0]?.id
        );
      });
  }

  async function userTimeZone() {
    if (webApp.value) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      return await googleSpreadsheetService.getDashboardTimeZone();
    }
  }

  async function loadCurrentGroupMessage(id: number | null) {
    // Receiving null as the id value sets a "No messages found" state
    if (!id) {
      selectedGroupMessage.value = null;
      return;
    }
    // Set to undefined to trigger loading state
    selectedGroupMessage.value = undefined;
    const timeZone = await userTimeZone();
    let request;

    if (webApp.value) {
      request = webAppRequest('GET', `/api/v2/mail_merge/group_messages/${id}`);
    } else {
      request = ribbonRequest('GET', `/api/v2/mail_merge/group_messages/${id}`);
    }

    request.then((groupMessageResponse: GroupMessageBEModel) => {
      selectedGroupMessage.value = groupMessageMapper.map(
        groupMessageResponse as GroupMessageBEModel,
        timeZone
      );

      currentPersonalMessage.value = selectedGroupMessage.value?.messages[0];
    });
  }

  async function cancelScheduledGroupMessage() {
    const messageId = selectedGroupMessage.value!.id;
    await request.value(
      'DELETE',
      `/api/v2/mail_merge/group_messages/${messageId}`
    );

    const deletedMessageIndex = messages.value?.findIndex(
      (sm) => sm.id == messageId
    );

    messages.value?.splice(deletedMessageIndex!, 1);
  }

  function closeDialog() {
    dialogOpened.value = false;
  }

  function openDialog() {
    dialogOpened.value = true;
  }

  function setWebAppFlow() {
    webApp.value = true;
  }

  // Adds a new groupMessage to the message list
  async function addGroupMessage(groupMessage: GroupMessageModel) {
    if (initialized.value) {
      messages.value!.unshift(groupMessage);
    }
  }

  return {
    init,
    messages,
    loadCurrentGroupMessage,
    selectedGroupMessage,
    cancelScheduledGroupMessage,
    closeDialog,
    openDialog,
    dialogOpened,
    setWebAppFlow,
    webApp,
    currentPersonalMessage,
    addGroupMessage,
  };
});
