import { createRouter, createWebHistory } from 'vue-router';

import ConnectedZoomMeetingsView from '../views/ZoomMeetings/ConnectedMeetingsView.vue';
import ComposeEmailView from '../views/Sidebar/ComposeEmailView.vue';
import AllEmailsView from '../views/Sidebar/AllEmailsView.vue';
import {
  DASHBOARD_VIEWS,
  DASHBOARD_OVERVIEW_VIEW_TYPE,
  DASHBOARD_CANVAS_ANALYTICS_VIEW_TYPE,
} from '@/models/Dashboard/BaseDashboard';

const appLive = false;

const appRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import('../views/Onboarding/LayoutView.vue'),
    children: [
      {
        path: 'canvas',
        name: 'Onboarding Canvas',
        component: () => import('../views/Onboarding/CanvasView.vue'),
      },
      {
        path: 'mail-merge',
        name: 'Onboarding Mail Merge',
        component: () => import('../views/Onboarding/MailMergeView.vue'),
      },
      {
        path: 'gmail',
        name: 'Onboarding Gmail',
        // leave this route but redirect to mail-merge
        // to be sure users who knows about lifehack to
        // reintegrate mail merge don't have bad UX.
        redirect: '/onboarding/mail-merge',
      },
    ],
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/sidebar',
    name: 'Sidebar Views',
    children: [
      {
        path: 'zoom_configurations/:id',
        // It's needed to make this route not reachable
        redirect: '/',
        children: [
          {
            path: 'meetings',
            component: ConnectedZoomMeetingsView,
          },
        ],
      },
      {
        path: 'configuration',
        component: () => import('@/views/ConfigView.vue'),
      },
      {
        path: 'compose_email',
        component: ComposeEmailView,
      },
      {
        path: 'all_emails',
        component: AllEmailsView,
      },
    ],
  },
  {
    path: '/callbacks',
    name: 'callbacks',
    component: () => import('@/views/callbacks/CallbacksView.vue'),
    children: [
      {
        path: 'zoom_oauth/:status',
        component: () => import('@/views/callbacks/ZoomCallbacksView.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/dashboards',
    component: () => import('@/views/dashboards/LayoutView.vue'),
    children: [
      {
        path: '/dashboards',
        name: 'Dashboards',
        component: () => import('@/views/dashboards/DashboardView.vue'),
        children: [
          {
            path: ':dashboardUuid/canvas-analytics',
            name: DASHBOARD_VIEWS[DASHBOARD_CANVAS_ANALYTICS_VIEW_TYPE],
            component: () => import('@/views/dashboards/DashboardView.vue'),
          },
          {
            path: ':dashboardUuid/overview',
            name: DASHBOARD_VIEWS[DASHBOARD_OVERVIEW_VIEW_TYPE],
            component: () => import('@/views/dashboards/DashboardView.vue'),
          },
        ],
      },
      {
        path: '/settings',
        name: 'User settings',
        component: () => import('../views/Settings/UserSettingsView.vue'),
      },
    ],
  },
  {
    path: '/canvas-lti/bad-request',
    name: 'canvas-lti-bad-request',
    component: () => import('@/views/canvasLti/BadRequestView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFoundView.vue'),
  },
];

const spinDownRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: () => import('@/views/spinDown/MainView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: appLive ? appRoutes : spinDownRoutes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name &&
    to.name!.toString() !=
      DASHBOARD_VIEWS[DASHBOARD_CANVAS_ANALYTICS_VIEW_TYPE] &&
    to.name!.toString() != DASHBOARD_VIEWS[DASHBOARD_OVERVIEW_VIEW_TYPE]
  ) {
    document.title = 'Ribbon - ' + to.name!.toString();
  }
  next();
});

export default router;
