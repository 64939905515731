import { RequestError, webAppRequest } from '@/api/clientV2';
import type { ActiveUser } from '@/models/ActiveUser';
import type AnalyticsDashboardFE from '@/models/Dashboard/Canvas/AnalyticsDashboard';
import type { OverviewDashboardFE } from '@/models/Dashboard/Canvas/OverviewDashboard';
import type { WriteForMeConfiguration } from '@/models/MailMessage';
import type MailMessage from '@/models/MailMessage';
import router from '@/router';
import LearnersMapper from '@/mappers/Bryntum/LearnersMapper';
import type { Recipient } from '@/models/MailMessage';
import { groupBy } from 'lodash';
import type { Grid } from '@bryntum/grid';
import type ComposeEmailRecipient from '@/models/ComposeEmail/Recipient';
import type GroupMessageModel from '@/models/GroupMessageModel';
import _ from 'lodash';

export const MAIL_MERGE_EMAIL_WORKFLOW = 'email';
export const MAIL_MERGE_CANVAS_MESSAGE_WORKFLOW = 'canvas_message';

export function isEmailFlow(
  mailMessage: MailMessage | GroupMessageModel | undefined
) {
  return mailMessage?.workflow == MAIL_MERGE_EMAIL_WORKFLOW;
}

export function isCanvasMessageFlow(mailMessage: MailMessage | undefined) {
  return mailMessage?.workflow == MAIL_MERGE_CANVAS_MESSAGE_WORKFLOW;
}

export class MailMergeMessageBuilder {
  activeUser: ActiveUser;
  isCanvasMessage: boolean;

  mailMessage: MailMessage;

  constructor(activeUser: ActiveUser, isCanvasMessage: boolean) {
    this.activeUser = activeUser;
    this.isCanvasMessage = isCanvasMessage;
  }

  buildMessage(dashboard: AnalyticsDashboardFE | OverviewDashboardFE) {
    this.mailMessage = {
      configurationId: dashboard.uuid,
      configurationType: dashboard.dashboardType,
      writeForMeConfiguration: {} as WriteForMeConfiguration,
      workflow: this.workflow(),
      recipients: [],
      inactiveConcludedSelectedRecipients: [],
      invalidEmailsSelectedRecipients: [],
    } as MailMessage;
  }

  workflow() {
    return this.isCanvasMessage
      ? MAIL_MERGE_CANVAS_MESSAGE_WORKFLOW
      : MAIL_MERGE_EMAIL_WORKFLOW;
  }

  withFromEmail() {
    if (this.isCanvasMessage) {
      return null;
    }

    this.mailMessage.fromEmail = this.activeUser.mailMerge.google
      ? this.activeUser.mailMerge.google.email
      : this.activeUser.mailMerge.microsoft!.email;
  }

  withAutoBcc() {
    if (this.isCanvasMessage || !this.activeUser.mailMerge.autoBcc.enabled) {
      return;
    }

    this.mailMessage.bccEnabled = true;
    this.mailMessage.bccString =
      this.activeUser.mailMerge.autoBcc.emails.join(',');
  }

  async authorize(activeUser: ActiveUser) {
    if (this.isCanvasMessage) {
      return;
    }

    if (!activeUser.mailMerge.integrated) {
      router.push(`/onboarding/mail-merge?action=composeEmail`);
      return;
    }

    try {
      await webAppRequest(
        'POST',
        '/api/v2/mail_merge/group_messages/can_create'
      );
    } catch (e) {
      if (e instanceof RequestError) {
        if (e.status == 403) {
          router.push('/onboarding/mail-merge?action=reconnect');
          return;
        }
      } else {
        throw e;
      }
    }
  }

  buildRecipients(grid: Grid) {
    const uniqueRecipients = groupBy(LearnersMapper.map(grid), (record) => {
      return record.learner.id;
    });

    const recipients: Array<ComposeEmailRecipient> = [];

    Object.keys(uniqueRecipients).forEach((learnerId) => {
      const recipientEnrollments = uniqueRecipients[learnerId];

      const emailInvalid =
        !this.isCanvasMessage && !recipientEnrollments[0].validEmail;

      const allEnrollmentsInactive = uniqueRecipients[learnerId].every(
        (learner) => !learner.active
      );

      const checkedRecipientEnrollments = recipientEnrollments.filter(
        (l) => l.checked
      );

      const recipient = {
        checked: !!checkedRecipientEnrollments.length,
        enrollmentIds: checkedRecipientEnrollments.map(
          (record) => record.learner.canvas?.enrollment.id
        ),
        learner: {
          id: recipientEnrollments[0].learner.id,
          name: recipientEnrollments[0].learner.name,
          email: recipientEnrollments[0].learner.email,
          courseId:
            recipientEnrollments[0].learner.canvas!.enrollment!.courseId,
        } as Recipient,
      } as ComposeEmailRecipient;

      if ((emailInvalid && !this.isCanvasMessage) || allEnrollmentsInactive) {
        recipient.disabled = {
          invalidEmail: emailInvalid,
          inactive: allEnrollmentsInactive,
        };

        if (recipient.checked && allEnrollmentsInactive) {
          this.mailMessage.inactiveConcludedSelectedRecipients.push(recipient);
        }

        if (recipient.checked && emailInvalid && !this.isCanvasMessage) {
          this.mailMessage.invalidEmailsSelectedRecipients.push(recipient);
        }

        recipient.checked = false;
      }

      recipients.push(recipient);
    });

    this.mailMessage.recipients = _.orderBy(recipients, [
      (user) => (user.learner.name || '').toLowerCase(),
    ]);
    return this.mailMessage.recipients;
  }
}
