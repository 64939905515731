import type MailMergeMessageBEModel from '@/models/MailMerge/MessageBEModel';
import type MailMergeMessageModel from '@/models/MailMerge/MessageModel';

export default {
  map(messageBE: MailMergeMessageBEModel, timeZone: string) {
    const message = messageBE as MailMergeMessageModel;

    message.formattedStatusUpdatedAt = new Date(
      messageBE.statusUpdatedAt
    ).toLocaleString('en-US', {
      timeZone: timeZone,
      timeZoneName: 'short',
    });

    return message;
  },
};
