import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import vuetify from '@/plugins/vuetify';

import * as Sentry from '@sentry/vue';
import mixpanel from 'mixpanel-browser';

import './assets/main.css';

declare global {
  interface Window {
    gtag: Function;
  }
}

const appEnv = import.meta.env.APP_ENV as string;
const app = createApp(App);

app.use(createPinia());
app.use(router);

router.afterEach((to, from, failure) => {
  // This compensates for router changes that don't change the path like
  // applying new query string params for filters
  const pathChanged = to.path !== from.path;

  if (window.gtag && pathChanged && !failure) {
    // We manually track page views because we don't want to send real
    // page titles to Google Analytics since they contain customer data
    // like course names, instead we use the path which only has the
    // dashboard UUID
    window.gtag('event', 'page_view', {
      page_title: window.location.pathname,
      page_path: window.location.pathname,
      page_location: document.location,
    });
  }
});

// Vuetify
app.use(vuetify);

const sentryDSN = import.meta.env.VITE_SENTRY_DSN as string;

if (sentryDSN) {
  Sentry.init({
    app,
    dsn: sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [import.meta.env.VITE_WEBAPP_URL as string],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN as string;

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    debug: appEnv === 'development',
    track_pageview: 'full-url',
    persistence: 'localStorage',
  });
}

app.mount('#app');
