import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAlertsStore = defineStore('alerts', () => {
  const body = ref('');
  const visible = ref(false);

  function showAlert(text: string) {
    body.value = text;
    visible.value = true;
  }
  return {
    visible,
    body,
    showAlert,
  };
});
