import { defineStore } from 'pinia';

import _ from 'lodash';
import { ref } from 'vue';
import { Store, Grid } from '@bryntum/grid';
import type CourseSectionBE from '@/models/Dashboard/Canvas/CourseSection';
import type { BryntumFilter } from '@/mappers/Bryntum/FilterSortQueryMapper';
import {
  CANVAS_COURSE_COLUMN_ID,
  CANVAS_SECTION_COLUMN_ID,
} from '@/plugins/Bryntum/Canvas/Dashboard';
import { useDashboardStore } from '../dashboard';

interface BryntumRow {
  id: string;
  courseId: number;
  sectionId: number;
}

export const useCurrentCanvasDataStore = defineStore(
  'currentCanvasStore',
  () => {
    const usedCourses = ref<Store>(new Store());
    const usedSections = ref<Store>(new Store());

    async function update() {
      const dashboardStore = useDashboardStore();
      let visibleCourseIds: Array<number> = [];
      let visibleSectionIds: Array<number> = [];

      // The easiest case when we just get possible values
      // from rows which are not hidden.
      (dashboardStore.grid!.data as Array<BryntumRow>).forEach((row) => {
        visibleCourseIds.push(row.courseId);
        visibleSectionIds.push(row.sectionId);
      });

      const appliedFilters = (
        dashboardStore.grid!.store.filters! as { values: Array<BryntumFilter> }
      ).values;

      const courseFilter = appliedFilters.find(
        (f) => f.id == CANVAS_COURSE_COLUMN_ID
      );
      const sectionFilter = appliedFilters.find(
        (f) => f.id == CANVAS_SECTION_COLUMN_ID
      );

      // it means we need deeper investigation.
      // According to Google spreadsheet filtering:
      // If filter is applied and possible value is hidden
      // by current filter ONLY we should display it.
      if (courseFilter || sectionFilter) {
        // The idea to make a copy of dashboard
        const dupGrid = new Grid(dashboardStore.dashboardData);

        if (sectionFilter) {
          // Apply all filters except Section to be sure
          // we can know exact rows which were filtered but not by
          // Section column.
          (dupGrid.store as { filter: Function }).filter(
            appliedFilters.filter(
              (filter) => filter.id != CANVAS_SECTION_COLUMN_ID
            )
          );
          // Get all data from visible rows
          (dupGrid.data as Array<BryntumRow>).forEach((row) => {
            visibleSectionIds.push(row.sectionId);
          });
        }

        // The same logic for course filter
        if (courseFilter) {
          (dupGrid.store as { filter: Function }).filter(
            appliedFilters.filter(
              (filter) => filter.id != CANVAS_COURSE_COLUMN_ID
            )
          );
          // Get all data from visible rows
          (dupGrid.data as Array<BryntumRow>).forEach((row) => {
            visibleCourseIds.push(row.courseId);
          });
        }
      }

      // Get uniq values.
      visibleCourseIds = _.uniq(visibleCourseIds);
      visibleSectionIds = _.uniq(visibleSectionIds);

      const allCourses = dashboardStore.responseBE!.canvas!.courses;

      // Calculating courses for dropdown
      usedCourses.value.data = _.compact(
        visibleCourseIds.map((courseId) => {
          const course = allCourses.find((c) => c.id == courseId);

          if (!course) {
            return;
          }

          return {
            id: course.id.toString(),
            name: course.name,
          };
        })
      );

      // Calculating sections for dropdown
      usedSections.value.data = _.compact(
        visibleSectionIds.map((sectionId) => {
          let section: CourseSectionBE;

          allCourses.forEach((c) => {
            if (section) return;
            section = c.sections.find((s) => s.id == sectionId)!;
          });

          // @ts-ignore
          if (!section) {
            return;
          }

          return {
            id: section!.id.toString(),
            name: section!.name,
          };
        })
      );
    }

    return {
      update,
      usedCourses,
      usedSections,
    };
  }
);
