import type { ActiveUser } from '@/models/ActiveUser';

export default {
  shortenedName(user: ActiveUser) {
    return this.nameInitials(user.name) || this.emailInitial(user.email);
  },
  nameInitials(name: string) {
    if (!name) {
      return '';
    }

    let firstInitial = '';
    let lastInitial = '';

    // If name contains 1 comma,
    // assume
    // the name before the comma is the last name and
    // the name after the comma is the first name
    if (name.match(/,/g)?.length == 1) {
      const splitName = name.split(',');
      const firstName = splitName[1].trim();
      const lastName = splitName[0].trim().split(' ').pop()?.trim();

      firstInitial = firstName[0];
      lastInitial = lastName ? lastName[0] : '';
    } else {
      const splitName = name.split(' ');
      const firstName = splitName[0].trim();

      firstInitial = firstName[0];

      if (splitName.length > 1) {
        const lastName = splitName[splitName.length - 1].trim();

        lastInitial = lastName[0];
      }
    }

    return `${firstInitial}${lastInitial}`.toUpperCase();
  },
  emailInitial(email: string) {
    const initials = email ? email[0] : '';

    return initials.toUpperCase();
  },
};
