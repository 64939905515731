import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Grid, type GridConfig } from '@bryntum/grid';
import router from '@/router';
import FilterSortQueryMapper, {
  type FilterSortQueryParams,
} from '@/mappers/Bryntum/FilterSortQueryMapper';

import type {
  OverviewDashboardBE,
  OverviewDashboardFE,
} from '@/models/Dashboard/Canvas/OverviewDashboard';

import type { AnalyticsDashboardBE } from '@/models/Dashboard/Canvas/AnalyticsDashboard';

import BryntumDashboardMapper from '@/mappers/Bryntum/DashboardMapper';
import type AnalyticsDashboardFE from '@/models/Dashboard/Canvas/AnalyticsDashboard';
import {
  updateLearnersHeader,
  type BryntumStore,
} from '@/plugins/Bryntum/Dashboard';

import DashboardAppearedScrollRightPlugin from '@/plugins/Bryntum/DashboardAppearedScrollRightPlugin';
import { useTimeZoneStore } from './timeZone';
import type { BuildStrategy } from '@/services/Dashboards/BuildStrategy';
import _ from 'lodash';

export const useDashboardStore = defineStore('dashboard', () => {
  const dashboardInfo = ref<AnalyticsDashboardFE | OverviewDashboardFE>();
  const dashboardUuid = ref<string>();

  const isDemoDashboard = ref(false);
  const loaded = ref(false);

  let initPromise: Promise<void> | null;

  const grid = ref<Grid>();

  const responseBE = ref<AnalyticsDashboardBE | OverviewDashboardBE>();

  const dashboardBuildStrategy = ref<BuildStrategy>();

  const dashboardsByCanvasUuid = ref();

  const dashboardData = ref<Partial<GridConfig>>();

  async function init(
    buildStrategy: BuildStrategy,
    archivedDashboard: boolean
  ) {
    dashboardBuildStrategy.value = buildStrategy;

    if (!initPromise) {
      initPromise = loadDashboard(buildStrategy, archivedDashboard);
      await initPromise;
    }
  }

  async function loadDashboard(
    buildStrategy: BuildStrategy,
    archivedDashboard: boolean
  ) {
    const queryFilterSortParams = FilterSortQueryMapper.fromQueryParams(
      router.currentRoute.value.query as unknown as FilterSortQueryParams
    );

    responseBE.value = buildStrategy.response;

    const responseFE = buildStrategy.dashboardMapper!.map(
      buildStrategy.response,
      buildStrategy,
      archivedDashboard
    );

    isDemoDashboard.value = Boolean(buildStrategy.response!.isDemo);

    dashboardData.value = BryntumDashboardMapper.map(
      buildStrategy.bryntumMapper!.map(responseFE, queryFilterSortParams),
      queryFilterSortParams
    ) as Partial<GridConfig>;

    dashboardInfo.value = responseFE;
    dashboardUuid.value = responseFE.uuid;

    useTimeZoneStore().dashboardTimeZone =
      dashboardInfo.value!.riskConfiguration.timezone;

    grid.value = new Grid({
      appendTo: document.getElementById(
        'bryntum-dashboard-container'
      ) as HTMLElement,
      ...dashboardData.value,
    });

    DashboardAppearedScrollRightPlugin.observe();
    DashboardAppearedScrollRightPlugin.observeResize();

    updateLearnersHeader(grid.value!.store as BryntumStore);

    dashboardsByCanvasUuid.value = _.groupBy(
      dashboardInfo.value!.courseDashboards,
      'canvas.course.uuid'
    );

    loaded.value = true;
  }

  function search(term: string) {
    if (!grid.value) {
      return;
    }

    grid.value.features.search.search(term);
  }

  function searchNext() {
    if (!grid.value) {
      return;
    }

    grid.value.features.search.gotoNextHit();
  }

  function searchClear() {
    if (!grid.value) {
      return;
    }

    grid.value.features.search.clear();
  }

  function clearDashboard() {
    loaded.value = false;
    initPromise = null;
    dashboardInfo.value = undefined;

    const dashboardContainer = document.getElementsByClassName('b-container');

    if (dashboardContainer.length > 0) {
      dashboardContainer[0].remove();
    }
  }

  function uncheckAllLearners() {
    grid.value?.deselectAll();
  }

  function selectLearner(id: number, dashboardUuid: string) {
    grid.value!.data.forEach((element) => {
      if (
        // @ts-ignore
        id == element.learner.id &&
        // @ts-ignore
        dashboardsByCanvasUuid.value[element.learner.canvas.courseUuid][0]
          .uuid == dashboardUuid
      ) {
        grid.value?.selectRow(element);
      }
    });
  }

  return {
    dashboardInfo,
    init,
    isDemoDashboard,
    loaded,
    clearDashboard,
    grid,
    // TODO-dashboard-uuid-ui once request will be not 10+ seconds can be reworked
    // we don't need to store uuid of old dashboard.
    dashboardUuid,
    uncheckAllLearners,
    search,
    searchNext,
    searchClear,
    selectLearner,
    responseBE,
    dashboardBuildStrategy,
    loadDashboard,
    dashboardsByCanvasUuid,
    dashboardData,
  };
});
