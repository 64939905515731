import {
  LearnerStatusFilter,
  type LearnerFE,
} from '@/models/Dashboard/Learner';
import BryntumCanvasDashboardHelpers from '@/plugins/Bryntum/Canvas/Dashboard';
import BryntumDashboardHelpers from '@/plugins/Bryntum/Dashboard';
import type BryntumUserAttribute from '@/plugins/Bryntum/UserAttribute';
import type { BaseDashboardFE } from '@/models/Dashboard/BaseDashboard';
import type { CourseDashboardFE } from '@/models/Dashboard';

import { v4 } from 'uuid';

import type BryntumColumnHeader from '@/plugins/Bryntum/ColumnHeader';

export const LEARNER_STATUS_KEY = 'current-status';

export const BRYNTUM_CANVAS_COLUMNS = {
  fullName: 'Name',
  firstName: 'FirstName',
  lastName: 'LastName',
  currentStatus: 'current-status',

  course: 'Course',
  section: 'Section',
};

export default {
  applyUsersInfo(learner: LearnerFE) {
    return [
      {
        key: 'id',
        value: v4(),
      },
      {
        key: 'Name',
        value: learner.name,
      },
      {
        key: 'name',
        value: learner.name,
      },
      {
        key: 'FirstName',
        value: learner.canvas?.firstName,
      },
      {
        key: 'LastName',
        value: learner.canvas?.lastName,
      },
      {
        key: 'learner',
        value: learner,
      },
      {
        key: LEARNER_STATUS_KEY,
        value: learner.currentStatus,
      } as BryntumUserAttribute,
      {
        key: 'missing-assignments',
        value: learner.canvas!.totalMissingAssignments,
      } as BryntumUserAttribute,
      {
        key: 'missing-assignments-period-percentage',
        value: this.calculateMissingAssignmentsPercent(
          learner.canvas!.currentPeriod?.totalMissingAssignments,
          learner.canvas!.currentPeriod?.totalDueAssignments
        ),
      } as BryntumUserAttribute,
      {
        key: 'grade',
        value: learner.canvas!.enrollment.currentScore,
      } as BryntumUserAttribute,
      {
        key: 'last-activity',
        value: learner.canvas!.daysSinceLastActivity,
      },
      {
        key: 'sectionId',
        // We need string here because bryntum renders it as number
        value: learner.canvas!.sectionId.toString(),
      },
      {
        key: 'sectionName',
        value: learner.canvas!.sectionName,
      },
      {
        // Ok i now it's ugly but don't want to make regression now.
        // Good tech dept for summer.
        key: 'Section',
        value: learner.canvas!.sectionName,
      },
      // Used to filter from learner tooltip
      {
        key: 'learner-id',
        value: learner.id,
      },
      {
        key: 'archived',
        value: learner.archived,
      },
    ];
  },
  applyUserColumns(dashboard: BaseDashboardFE) {
    const userColums = [
      {
        id: BRYNTUM_CANVAS_COLUMNS.currentStatus,
        name: 'Current<br>Status',
        plainText: 'Current Status',
        searchable: false,
        maxWidth: 100,
        renderer: BryntumCanvasDashboardHelpers.dashboardStatusRenderer,
        tooltipRenderer:
          BryntumCanvasDashboardHelpers.tooltipCurrentStatusRenderer,
        filterable: {
          filterFn: BryntumDashboardHelpers.filterGroupedFn,
          filterField: {
            type: 'combo',
            multiSelect: true,
            items: LearnerStatusFilter,
          },
        },
      } as BryntumColumnHeader,
      this.showTotalMissingAssignmentsColumn(dashboard)
        ? ({
            id: 'missing-assignments',
            name: '# of Missing<br>Assignments',
            plainText: '# of Missing Assignments',
            renderer: BryntumDashboardHelpers.htmlRenderer,
            type: 'number',
          } as BryntumColumnHeader)
        : null,
      this.showMissingAssignmentsPercentColumn(dashboard)
        ? ({
            id: 'missing-assignments-period-percentage',
            name: '% Missing<br>Assignments',
            plainText: '% Missing Assignments',
            renderer:
              BryntumCanvasDashboardHelpers.missingAssignmentsPercentageRenderer,
            headerRenderer: this.missingAssignmentsPercentHeaderRenderer,
            type: 'number',
          } as BryntumColumnHeader)
        : null,
      {
        id: 'grade',
        name: 'Grade',
        plainText: 'Grade',
        renderer: BryntumCanvasDashboardHelpers.gradeRenderer,
        sortable: BryntumCanvasDashboardHelpers.gradeSortFunction,
        type: 'number',
      } as BryntumColumnHeader,
      {
        id: 'last-activity',
        name: 'Days since<br>Last Activity',
        plainText: 'Days since Last Activity',
        renderer: BryntumCanvasDashboardHelpers.lastActivityRenderer,
        type: 'number',
      } as BryntumColumnHeader,
    ];

    return userColums.filter(
      (column) => column !== null
    ) as BryntumColumnHeader[];
  },
  calculateMissingAssignmentsPercent(totalMissing: number, totalDue: number) {
    if (!totalDue) {
      return null;
    }

    return (totalMissing / totalDue) * 100;
  },
  showTotalMissingAssignmentsColumn(dashboard: BaseDashboardFE) {
    return !this.showMissingAssignmentsPercentColumn(dashboard);
  },
  showMissingAssignmentsPercentColumn(dashboard: BaseDashboardFE) {
    if (!dashboard.courseDashboards) {
      return false;
    }

    return dashboard.courseDashboards.every(
      (courseDashboard: CourseDashboardFE) => {
        return (
          courseDashboard.riskCanvasCourseConfiguration
            .gtePercentMissingInPeriod !== null
        );
      }
    );
  },
  missingAssignmentsPercentHeaderRenderer: ({
    column,
    headerElement,
  }: {
    column: { id: string; text: string };
    headerElement: HTMLElement;
  }) => {
    const tooltip = `
      <p>
        This calculation represents the percentage
        of assignments that are due but missing
        during this specific period.
      </p>
    `;

    headerElement?.setAttribute('data-btip', tooltip);

    return column.text;
  },
};
