import { ref } from 'vue';
import { defineStore } from 'pinia';
import googleSpreadsheetService from '@/GoogleSpreadsheetService';

// This Pinia store is meant to handle the state of whichever user-facing error is being shown right now.
// It doesn't care *how* will it be displayed, it simply stores the error data
// Any component that needs to show an error to the user can import the store and use its features
export const useTimeZoneStore = defineStore('timeZone', () => {
  const isWebapp = ref(false);

  const dashboardTimeZone = ref<string>();

  function setWebapp() {
    isWebapp.value = true;
  }

  async function userTimeZone() {
    if (isWebapp.value) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      return await googleSpreadsheetService.getDashboardTimeZone();
    }
  }

  function dateInDashboardTimeZone(date: Date, params: {}) {
    const enUSFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone: dashboardTimeZone.value,
      ...params,
    });

    return enUSFormatter.format(date);
  }

  return {
    userTimeZone,
    setWebapp,
    dashboardTimeZone,
    dateInDashboardTimeZone,
  };
});
